import { AssetLogo } from "../../../assets";

const Footer = () => {
    const pagesNavigate = process.env.REACT_APP_PAGES;

    const onNavigate = (path) => {
        window.open(path, '_self');
    }

    return (
        <div className="border border-t-2 border-slate-200 mt-20 pt-5 pb-10">
            <div className="flex justify-center">
                <div className="px-3 w-full md:w-[95%] max-w-[80rem]">
                    <div className="flex flex-wrap gap-10 justify-between">
                        <div className="flex flex-wrap gap-28">
                            <div>
                                <div>
                                    <span className="font-bold text-lg">Pusproject</span>
                                    <ul className="mt-1">
                                        <li className="cursor-pointer" onClick={() => onNavigate(`${pagesNavigate}/about`)}>Tentang Pusproject</li>
                                        <li className="cursor-pointer" onClick={() => onNavigate(`${pagesNavigate}/intellectual-property-protection`)}>Hak Kekayaan Intelektual</li>
                                    </ul>
                                </div>
                                <div className="mt-3">
                                    <span className="font-bold text-lg">Layanan Pelanggan</span>
                                    <ul className="mt-1">
                                        <li className="cursor-pointer" onClick={() => onNavigate(`${pagesNavigate}/help-center`)}>Pusat Bantuan</li>
                                        <li className="cursor-pointer" onClick={() => onNavigate(`${pagesNavigate}/terms-and-conditions`)}>Syarat dan Ketentuan</li>
                                        <li className="cursor-pointer" onClick={() => onNavigate(`${pagesNavigate}/privacy-policy`)}>Kebijakan Privasi</li>
                                        <li className="cursor-pointer" onClick={() => onNavigate(`${pagesNavigate}/faq`)}>FAQ</li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span className="font-bold text-lg">Kontak</span>
                                    <ul className="mt-1">
                                        <li>Email: pusproject.studio@gmail.com</li>
                                    </ul>
                                </div>
                                <div className="mt-3">
                                    <span className="font-bold text-lg">Ikuti Kami</span>
                                    <div className="flex gap-2 mt-1">
                                        <span className="cursor-pointer" onClick={() => window.open('https://www.instagram.com/pusproject_official', '_blank')}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z"></path>
                                                <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                                                <path d="M16.5 7.5l0 .01"></path>
                                            </svg>
                                        </span>
                                        <span className="cursor-pointer" onClick={() => window.open('https://id.pinterest.com/pusproject_studio', '_blank')}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M8 20l4 -9"></path>
                                                <path d="M10.7 14c.437 1.263 1.43 2 2.55 2c2.071 0 3.75 -1.554 3.75 -4a5 5 0 1 0 -9.7 1.7"></path>
                                                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                            </svg>
                                        </span>
                                        <span className="cursor-pointer" onClick={() => window.open('https://discord.gg/NQjtP7CZ', '_blank')}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M14.983 3l.123 .006c2.014 .214 3.527 .672 4.966 1.673a1 1 0 0 1 .371 .488c1.876 5.315 2.373 9.987 1.451 12.28c-1.003 2.005 -2.606 3.553 -4.394 3.553c-.732 0 -1.693 -.968 -2.328 -2.045a21.512 21.512 0 0 0 2.103 -.493a1 1 0 1 0 -.55 -1.924c-3.32 .95 -6.13 .95 -9.45 0a1 1 0 0 0 -.55 1.924c.717 .204 1.416 .37 2.103 .494c-.635 1.075 -1.596 2.044 -2.328 2.044c-1.788 0 -3.391 -1.548 -4.428 -3.629c-.888 -2.217 -.39 -6.89 1.485 -12.204a1 1 0 0 1 .371 -.488c1.439 -1.001 2.952 -1.459 4.966 -1.673a1 1 0 0 1 .935 .435l.063 .107l.651 1.285l.137 -.016a12.97 12.97 0 0 1 2.643 0l.134 .016l.65 -1.284a1 1 0 0 1 .754 -.54l.122 -.009zm-5.983 7a2 2 0 0 0 -1.977 1.697l-.018 .154l-.005 .149l.005 .15a2 2 0 1 0 1.995 -2.15zm6 0a2 2 0 0 0 -1.977 1.697l-.018 .154l-.005 .149l.005 .15a2 2 0 1 0 1.995 -2.15z" strokeWidth="0" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end items-center">
                            <div className="text-center">
                                <div className="flex justify-center">
                                    <AssetLogo width="169" height="49" />
                                </div>
                                <div className="mt-3">
                                    <span>
                                        &copy; Pusproject 2023, <br />Semua hak cipta dilindungi oleh undang-undang.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;